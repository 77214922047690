import FdxUI from '@ajs/services/fdxUI';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorType } from '@app/core/models/enums/error-type.enum';
import { HandledError } from '@app/core/models/types/handled-error.type';
import { LogService } from '@app/core/services/log.service';

@Injectable({
    providedIn: 'root'
})
export class ErrorService {
    public static readonly GENERIC_MESSAGE: string = 'An error occurred. Please try again.';

    constructor(
        private readonly fdxUI: FdxUI,
        private readonly log: LogService
    ) { }

    /**
     * Attempts to get an error message from an API response.
     * @param e response from API - handles object or string
     * @returns string | null
     */
    public getErrorMessage(e: unknown): string | null {
        switch (typeof e) {
            case 'object':
                return this.parseErrorObject(e);

            case 'string':
                return e;

            default:
                return null;
        }
    }

    /**
     * Attempts to parse an error message from an error object returned from an API response.
     * @param e JSON object response from API
     */
    private parseErrorObject(e: object): string | null {
        if (e === null) {
            return null;
        }

        if (!Object.entries(e).length) {
            return null;
        }

        /**
         * API error response format #1
         * ```json
         * {
         *   "status": "fail",
         *   "code": 400,
         *   "message": "Please choose a specific export!"
         * }
         * ```
         */
        if (
            'message' in e &&
            typeof e.message === 'string'
        ) {
            return e.message;
        }

        /**
         * API error response format #2
         * ```json
         * {
         *   "status": "error",
         *   "error": {
         *     "code": "VALIDATION_EXCEPTION",
         *     "message": "Recipient data missing or invalid (hint: check email)"
         *   }
         * }
         * ```
         */
        if (
            'error' in e &&
            typeof e.error === 'object' &&
            e.error !== null &&
            'message' in e.error &&
            typeof e.error.message === 'string'
        ) {
            return e.error.message;
        }

        // return pretty print JSON string so maybe the user gets some helpful info from it
        return JSON.stringify(e, undefined, 4);
    }

    /**
     * Handles an error raised from an API request using the `Http` Angular service.
     * Will try to parse a helpful error message from the error and then toast it.
     *
     * Typical usage:
     * ```typescript
     * this.observable$
     *     .pipe(
     *         tap((data) => doStuff(data)),
     *         catchError((e) => of(this.errorService.handleError(e))),
     *         takeUntil(this.unsubscribe$)
     *     )
     *     .subscribe();
     * ```
     *
     * Usage with custom error handling:
     * ```typescript
     * this.observable$
     *     .pipe(
     *         tap((data) => doStuff(data)),
     *         catchError((e) => {
     *             const model = this.errorService.handleError(e);
     *
     *             // add a switch if you want to do custom handling
     *             switch (model.type) {
     *                 case ErrorType.Http:
     *                     // model.source is HttpErrorResponse
     *                     if (model.source.status === 400) {
     *                         this.errorMessage = 'Something was wrong with your request.';
     *                     } else {
     *                         this.errorMessage = model.message;
     *                     }
     *                     break;
     *
     *                default:
     *                     this.errorMessage = model.message;
     *                     break;
     *             }
     *
     *             return of(model);
     *         }),
     *         takeUntil(this.unsubscribe$)
     *     )
     *     .subscribe();
     * ```
     *
     * Or it can be called with options:
     * ```typescript
     * this.observable$
     *     .pipe(
     *         tap((data) => doStuff(data)),
     *         catchError((e) => {
     *             const model = this.errorService.handleError(
     *                 error,
     *                 {
     *                     toast: false,
     *                     genericMessage: 'Failed to save.'
     *                 }
     *             );
     *
     *             return of(model);
     *         }),
     *         takeUntil(this.unsubscribe$)
     *     )
     *     .subscribe();
     * ```
     * @param error HttpErrorResponse | Error | unknown
     * @param options optional parameters
     * @returns HandledError - discriminated union of HandledUnknownError | HandledGenericError | HandledHttpError
     */
    public handleError(error: unknown, { toast = true, genericMessage,  dismissOtherToasts, timeout, dismissOnTimeout, alwaysUseGeneric }: { toast?: boolean; genericMessage?: string; dismissOtherToasts?: boolean; timeout?: number; dismissOnTimeout?: boolean; alwaysUseGeneric?: boolean; } = {}): HandledError {
        const model = this.getErrorDataModel(error, { genericMessage, alwaysUseGeneric });

        this.log.error('[ErrorService] Error handled:', model);

        if (toast && model.message) {
            this.fdxUI.showToastError(model.message, dismissOtherToasts, timeout, dismissOnTimeout);
        }

        return model;
    }

    private getErrorDataModel(error: unknown, { genericMessage, alwaysUseGeneric }: { genericMessage?: string; alwaysUseGeneric?: boolean; } = {}): HandledError {
        if (error instanceof HttpErrorResponse) {
            return {
                type: ErrorType.Http,
                source: error,
                message: (!alwaysUseGeneric && this.getErrorMessage(error.error)) ?? genericMessage ?? ErrorService.GENERIC_MESSAGE
            };
        }

        if (error instanceof Error) {
            return {
                type: ErrorType.Generic,
                source: error,
                message: (!alwaysUseGeneric && error.message) ?? genericMessage ?? ErrorService.GENERIC_MESSAGE
            };
        }

        return {
            type: ErrorType.Unknown,
            source: error,
            message: (!alwaysUseGeneric && this.getErrorMessage(error)) ?? genericMessage ?? ErrorService.GENERIC_MESSAGE
        };
    }
}
