<modal-hoc [modalTitle]="modalTitle"
    [submitButton]="true"
    [cancelButton]="true"
    (modalCancel)="onModalCancel($event)"
    (modalSubmit)="onModalSubmit($event)"
    [submitText]="submitText"
    [modalTheme]="export?.paused === '1' ? 'warning' : 'primary'">
    <div class="mb-3" *ngIf="export?.paused === '1'">
        @if (bigCommerceSelfService.showRestrictedUI()) {
            The sync for this channel is paused. Manually syncing may update your data in an unexpected way. Do you still want to manually sync?
        } @else {
            This export is paused. Pushing this export may update your data in an unexpected way. Do you still want to export?
        }
    </div>

    <div class="mb-3" *ngIf="export?.paused === '0'">Please confirm this information is correct before proceeding.</div>
    <table class="table table-rounded">
        <tbody>
            @if (!bigCommerceSelfService.showRestrictedUI()) {
                <tr>
                    <th>Destination</th>
                    <td>
                        <ng-container *ngIf="destinationUrl else noDestination">
                            {{destinationUrl}}
                        </ng-container>
                        <ng-template #noDestination>
                            <div class="fst-italic p-0">None</div>
                        </ng-template>
                    </td>
                </tr>
            }
            <tr>
                <th>
                    @if (bigCommerceSelfService.showRestrictedUI()) {
                        Name
                    } @else {
                        Export name
                    }
                </th>
                <td>{{export?.name}}</td>
            </tr>
            <tr *ngIf="export?.paused !== '1'">
                <th>Next scheduled run</th>
                <td>
                    <ng-container *ngIf="export?.cron else unscheduled">
                        {{timeToNextExport}} at
                        <fdx-date-time-localized [dateTime]="nextScheduledExport" [timezone]="export.cron_timezone"
                            class="px-0"></fdx-date-time-localized>
                    </ng-container>
                    <ng-template #unscheduled>
                        <div class="fst-italic p-0">Unscheduled</div>
                    </ng-template>
                </td>
            </tr>
        </tbody>
    </table>
</modal-hoc>
